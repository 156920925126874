import React from "react";
import { Carousel } from 'antd';

const imageSets = {
    'neily': [
      '/assets/hotel_ciudad_neily/habitacion-neily-1.jpg',
      '/assets/hotel_ciudad_neily/habitacion-neily-2.JPEG',
      '/assets/hotel_ciudad_neily/habitacion-neily-3.JPEG',
      '/assets/hotel_ciudad_neily/habitacion-neily-4.JPEG',
      '/assets/hotel_ciudad_neily/habitacion-neily-5.JPEG',

    ],
    'rio-claro': [
      '/assets/hotel_rio_claro/habitacion-rc-1.JPG',
      '/assets/hotel_rio_claro/habitacion-rc-2.JPG',
      '/assets/hotel_rio_claro/habitacion-rc-3.JPG',
    ],
};


const RoomCarousels = ({location}) => {
    const images = imageSets[location] || []; // Fallback to an empty array if the location is not found
    return(
        <Carousel autoplay>
            {images.map((image, index) => (
                <div key={index}>
                    <img src={image} alt={'Habitación ${index}'} className="w-full h-[25rem] md:h-[32rem] lg:h-[35rem] object-cover" />
                </div>
            ))}
        </Carousel>
    );
}

export default RoomCarousels;