import React from "react";
import { Phone, Mail } from 'lucide-react';

const Footer = () => {

    return (
        <div className="flex flex-col bg-black h-[25rem] w-full text-white shadow-md justify-center items-center">
            <div className="flex flex-col md:flex-row justify-center items-center my-16">
                <p className="font-bold text-center text-white md:mr-4 mb-8 md:mb-0"> Hotel Río Claro </p>
                <a href="tel:+50627899921" className="flex items-center mr-4">
                    <Phone size={26} className="mr-4 mb-4 md:mb-0" />
                    +506 2789 9921 
                </a>
                <a href="mailto:hotelimpalario1@gmail.com" className="flex items-center mr-4">
                    <Mail size={26} className="mr-4 mb-4 md:mb-0" />
                    hotelimpalario1@gmail.com
                </a>
            </div>
            <div className="flex flex-col md:flex-row mb-16 justify-center items-center">
                <p className="font-bold text-center text-white md:mr-4 mb-8 md:mb-0"> Hotel Ciudad Neily </p>
                <a href="tel:+50627831911" className="flex items-center mr-4">
                    <Phone size={26} className="mr-4 mb-4 md:mb-0" />
                    +506 2783 1911
                </a>
                <a href="mailto:hotelimpalados@gmail.com" className="flex items-center mr-4">
                    <Mail size={26} className="mr-4 mb-4 md:mb-0" />
                    hotelimpalados@gmail.com
                </a>
            </div>
            
        </div>
    );
}

export default Footer;