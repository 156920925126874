import React from "react";
import Carousels from "../components/Carousels";
import Services from "../components/Services";
import { Phone, Mail } from "lucide-react";

const About = () => {

    return(
        <div>
            <div className="relative flex items-center justify-center min-h-[30rem] md:h-[45rem] w-full bg-hotel-background bg-cover bg-top md:bg-center">
                <span className="z-10 font-bold text-white text-4xl md:text-7xl rounded p-2 font-outline-black text-center md:mb-32">
                    Bienvenidos al Hotel el Gran Impala
                </span>
            </div>
            <div className="flex flex-col md:flex-row justify-between items-start mx-4 md:mx-10 lg:mx-16 xl:mx-20 2xl:mx-48">
                <div className="flex-1 md:w-1/2">
                    <p className="font-bold text-3xl text-center mt-16 mb-10 ">¿Quiénes somos?</p>
                    <div className="text-xl text-justify text-gray-600 mb-16 mx-16 md:mx-0">
                        <p> Somos una empresa hotelera de Costa Rica. Nosotros iniciamos labores desde el año 1980. Empezamos con el hotel impala
                            ubicado en la ciudad de Río Claro de Golfito. Nos encontramos frente a la carretera Interamericana,
                            en el centro de Río Claro.
                        </p>
                        &nbsp;
                        <p>
                            Desde el año 2010, inicia labores nuestro segundo Hotel Impala, ubicado en la ciudad de Corredores,
                            Ciudad Neily, frente a carretera Interamericana.
                        </p>
                        &nbsp;
                        <p>
                            Nos enfocamos en el mejor servicio, buscando satisfacer las necesidades del viajero, tanto de negocios como
                            de placer. Estamos ubicados en el corazón de la zona sur, en sitios estratégicos para poder
                            desplazarse a los distintos establecimientos de compras y turísticos en la zona sur del país.
                        </p>
                        &nbsp;
                        <p>
                            Nuestra misión es hacerlo sentir un ambiente de seguridad y confort para que su estadía sea de su agrado.
                        </p>
                    </div>
                    <p className="font-bold text-3xl mb-4 text-center lg:mt-32"> Contacto </p>
                    <div className="flex flex-col xl:flex-row mb-16 items-center justify-center">
                        <p className="font-bold mx-4">Hotel Río Claro</p>
                        <a href="tel:+50627899921" className="flex items-center mr-4">
                            <Phone size={26} className="mr-2" />
                            +506 2789 9921
                        </a>
                        <a href="mailto:hotelimpalario1@gmail.com" className="flex items-center">
                            <Mail size={26} className="mr-2" />
                            hotelimpalario1@gmail.com
                        </a>
                    </div>
                    <div className="flex flex-col xl:flex-row items-center justify-center">
                        <p className="font-bold mx-4">Hotel Ciudad Neily</p>
                        <a href="tel:+50627831911" className="flex items-center mr-4">
                            <Phone size={26} className="mr-2" />
                            +506 2783 1911 
                        </a>
                        <a href="mailto:hotelimpalados@gmail.com" className="flex items-center mr-4">
                            <Mail size={26} className="md:mr-4 mb-4 md:mb-0" />
                            hotelimpalados@gmail.com
                        </a>
                    </div>
                </div>
                <div className="w-full md:w-1/2 mb-16 md:mb-0 md:ml-8 lg:ml-24 max-w-[50rem] mx-auto">
                    <Carousels/>
                </div>
            </div>
            <div className="h-0.5 bg-gray-300 w-3/4 mx-auto my-10"></div>
            <Services breakfast={true}/>
    </div>
);
}

export default About;

