import React from 'react';
import { Routes, Route} from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import About from './pages/About';
import Habitacion from './pages/Habitacion';
import ScrollToTop from './components/ScrollToTop';


function App() {
  return (
    <div>
      <ScrollToTop/>
      <Header/>
      <Routes>
        <Route path="/" element={<About/>}></Route>
        <Route path="/habitacion/:locationName" element={<Habitacion/>}></Route>
      </Routes>
      <Footer/>
    </div>
  )
}

export default App;