import React from 'react';
import RoomCarousels from '../components/RoomCarousels';
import Services from '../components/Services';
import HotelLocationMap from '../components/Maps';
import { useLocation } from 'react-router-dom';

const Habitacion = () => {
    const location = useLocation();
    const locationString = location.state.locationName;
    console.log(locationString);
    const titleName = (() => {
        if(locationString === 'neily'){
            return 'Ciudad Neily'
        } else{
            return 'Río Claro'
        }
    })();
    const breakfast = (() => {
        if(locationString === 'neily'){
            return false;
        } else {
            return true;
        }
    })();
    return (
        <div className='flex justify-center'>
            <div className='flex flex-col justify-around items-center mt-24 w-full max-w-6xl'>
                <div className="flex flex-col w-full  h-auto my-8">
                    <p className='self-center font-bold text-xl md:text-3xl mb-4 text-center'> Habitaciones del Hotel Impala de {titleName} </p>
                    <RoomCarousels location={locationString}/>
                </div>
                <Services breakfast={breakfast}/>
                <div className='flex flex-col w-full h-auto my-8 items-center'>
                    <p className='self-center font-bold text-3xl mb-10 xl:mb-4'>Ubicación</p>
                    <HotelLocationMap location={locationString}/>
                </div>
            </div>
        </div>
    );
}

export default Habitacion;
