import React from 'react';
import { Carousel } from 'antd';
import { NavLink } from 'react-router-dom';

const Carousels = () => {
  return (
    <div className='text-center'>
    
      <Carousel autoplay className="mt-16 mb-4">
        <div>
          <img src="/assets/hotel_rio_claro/hotel-impala-rc-1.jpeg" alt="Hotel Río Claro 1" className='w-full h-96 object-cover' />
        </div>
        <div>
          <img src="/assets/hotel_rio_claro/hotel-impala-rc-2.jpeg" alt="Hotel Río Claro 2" className='w-full h-96 object-cover' />
        </div>
        <div>
          <img src="/assets/hotel_rio_claro/hotel-impala-rc-3.jpeg" alt="Hotel Río Claro 3" className='w-full h-96 object-cover' />
        </div>
        <div>
          <img src="/assets/hotel_rio_claro/hotel-impala-rc-4.JPG" alt="Hotel Río Claro 4" className='w-full h-96 object-cover' />
        </div>
        <div>
          <img src="/assets/hotel_rio_claro/hotel-impala-rc-5.JPG" alt="Hotel Río Claro 5" className='w-full h-96 object-cover' />
        </div>
        <div>
          <img src="/assets/hotel_rio_claro/hotel-impala-rc-6.JPG" alt="Hotel Río Claro 6" className='w-full h-96 object-cover' />
        </div>
        <div>
          <img src="/assets/hotel_rio_claro/hotel-impala-rc-7.JPG" alt="Hotel Río Claro 7" className='w-full h-96 object-cover' />
        </div>
      </Carousel>
      <NavLink to="/habitacion/rio-claro" state={{locationName:'rio-claro'}} className="text-blue-500 underline underline-blue-500 hover:underline-blue-700 hover:text-blue-700"> Hotel Impala Río Claro </NavLink>
      <Carousel autoplay className='mt-16 mb-4'>
        <div>
          <img src="/assets/hotel_ciudad_neily/hotel-impala-neily-1.JPG" alt="Hotel Neily 1" className='w-full h-96 object-cover' />
        </div>
        <div>
          <img src="/assets/hotel_ciudad_neily/hotel-impala-neily-2.JPEG" alt="Hotel Neily 2" className='w-full h-96 object-cover' />
        </div>
        <div>
          <img src="/assets/hotel_ciudad_neily/hotel-impala-neily-3.JPG" alt="Hotel Neily 3" className='w-full h-96 object-cover' />
        </div>
        <div>
          <img src="/assets/hotel_ciudad_neily/hotel-impala-neily-4.JPEG" alt="Hotel Neily 4" className='w-full h-96 object-cover' />
        </div>
        <div>
          <img src="/assets/hotel_ciudad_neily/hotel-impala-neily-5.JPEG" alt="Hotel Neily 5" className='w-full h-96 object-cover' />
        </div>
        <div>
          <img src="/assets/hotel_ciudad_neily/hotel-impala-neily-6.JPEG" alt="Hotel Neily 6" className='w-full h-96 object-cover' />
        </div>
        <div>
          <img src="/assets/hotel_ciudad_neily/hotel-impala-neily-7.JPEG" alt="Hotel Neily 7" className='w-full h-96 object-cover'  />
        </div>
      </Carousel>
      <NavLink to="/habitacion/neily" state={{locationName:'neily'}} className="text-blue-500 underline underline-blue-500 hover:underline-blue-700 hover:text-blue-700"> Hotel Impala Ciudad Neily </NavLink>
    </div>
  );
};

export default Carousels;
