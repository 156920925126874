import React from "react";

import {Wifi, AirVent, Fan, ThermometerSun, SquareParking, Utensils, Feather, Refrigerator, Accessibility} from 'lucide-react';

const Services = ({breakfast}) => {
    return(
        <div className="text-center mx-8">
            <p className="font-bold text-3xl mb-12">
                Servicios
            </p>
            <div className="grid grid-cols-2 gap-x-4 gap-y-8 items-center md:grid-cols-4">
                <div className="flex flex-col items-center">
                    <Wifi size={32}/>
                    <p className="text-center whitespace-normal break-words mt-4 mb-4 md:mb-0"> Wifi gratis</p>
                </div>
                <div className="flex flex-col items-center">
                    <AirVent size={32}/>
                    <p className="text-center whitespace-normal break-words mt-4 mb-4 md:mb-0"> Aire acondicionado</p>
                </div>
                <div className="flex flex-col items-center">
                    <Fan size={32}/>
                    <p className="text-center whitespace-normal break-words mt-4 mb-4 md:mb-0"> Ventilador </p>
                </div>
                <div className="flex flex-col items-center">
                    <ThermometerSun size={32}/>
                    <p className="text-center whitespace-normal break-words mt-4 mb-4 md:mb-0"> Agua caliente </p>
                </div>
                <div className="flex flex-col items-center">
                    <SquareParking size={32}/>
                    <p className="text-center whitespace-normal break-words mt-4 mb-4 md:mb-0"> Estacionamiento privado</p>
                </div>
                <div className="flex flex-col items-center">
                    <Feather size={32}/>
                    <p className="text-center whitespace-normal break-words mt-4 mb-4 md:mb-0"> Servicio de limpieza <br></br>en las habitaciones</p>
                </div>
                <div className="flex flex-col items-center">
                    <Refrigerator size={32}/>
                    <p className="text-center whitespace-normal break-words mt-4 mb-4 md:mb-0"> Mini refrigeradora </p>
                </div>
                <div className="flex flex-col items-center">
                    <Accessibility size={32}/>
                    <p className="text-center whitespace-normal break-words mt-4 mb-4 md:mb-0"> Habitaciones adaptadas para <br></br> personas con discapacidad</p>
                </div>
                <div className="col-span-2 md:col-span-4">
                    {breakfast && (
                        <div className="flex flex-col items-center mb-4">
                            <Utensils size={32} className="mr-2" style={{ flexShrink: 0 }}/>
                            <span>Restaurante con desayuno incluido</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Services;