"use client";
import {
    APIProvider,
    Map,
    AdvancedMarker,
    Pin,
} from "@vis.gl/react-google-maps";

const neilyLocation = {
	lat: 8.63998054593891,
	lng: -82.95047526742931
}

const rioClaroLocation = {
	lat: 8.678686232419457,
	lng: -83.06818184687945
}

const locations = {
	'neily': neilyLocation,
	'rio-claro': rioClaroLocation
}

const locationNames = ['neily', 'rio-claro'];

const HotelLocationMap = ({location}) => {
	const mapId =
    location === locationNames[0]
      ? process.env.REACT_APP_MAPS_NEILY_ID
      : process.env.REACT_APP_MAPS_RIO_CLARO_ID;
	
	return (
		<APIProvider apiKey={process.env.REACT_APP_MAPS_API_KEY}>
				<div className=" h-[28rem] w-screen md:w-[28rem] lg:h-[35rem] lg:w-[30rem] 2xl:w-[35rem] ">
						<Map defaultZoom={11} defaultCenter={locations[location]} mapId={mapId}>
							<AdvancedMarker position={locations[location]}>
								<Pin></Pin>
							</AdvancedMarker>
						</Map>
				</div>
		</APIProvider>
	);
}

export default HotelLocationMap;