import React  from "react";
import { NavLink } from "react-router-dom";

const Header = () => {

    return (
        <div className="fixed w-full z-50">
            <div className="bg-gradient-to-r from-white to-red-600 shadow-md p-4 flex">
                <NavLink to="/">
                    <h1 className="text-primary text-center font-bold text-xl">Hotel Impala</h1>
                </NavLink>
                <div className="flex flex-row mx-8">
                    <NavLink to="/habitacion/rio-claro" state={{locationName:'rio-claro'}} className="hover:bg-gray-200 hover:text-blue-500 bg-white text-center p-2 rounded-md mx-4">
                        <span className="lg:mx-4 underline underline-offset-0 md:font-normal">Hotel Río Claro</span>
                    </NavLink>
                    <NavLink to="/habitacion/neily" state={{locationName:'neily'}} className="hover:bg-gray-200 hover:text-blue-500 bg-white text-center p-2 rounded-md ml-4">
                        <span className="lg:mx-4 underline">Hotel Ciudad Neily</span>
                    </NavLink>           
                </div>
            </div>
        </div>
    );
}

export default Header;